import React, { useContext, useEffect, useMemo, useReducer } from 'react';
import { useRouter } from 'next/router';

import Filter from 'services/filterClient';

const FiltersContextState = React.createContext(
  new Error('useFiltersState must be used within FiltersProvider!')
);

const FiltersContextActions = React.createContext(
  new Error('useFiltersActions must be used within FiltersProvider!')
);

FiltersContextState.displayName = 'FiltersContextState';
FiltersContextActions.displayName = 'FiltersContextActions';

function _removeCatchAll(pathname) {
  return pathname.replace('/[...params]', '');
}

const reducer = (state, action) => {
  return typeof action === 'function' ? action(state) : { ...state, ...action };
};

function _initReducer({ asPath, pathname }) {
  const filtersManager = new Filter(asPath);
  const { filters } = filtersManager;

  const as = _removeCatchAll(pathname);
  const date = filtersManager.getDateFilter();
  const filterStr = filtersManager.composeFilterStr();
  const filterAsQuery = filtersManager.formatToQueryStrings();
  const filterParamStr = filtersManager.composeFilterParam();
  const filterQueryStr = filtersManager.composeFilterQuery();

  return {
    filtersManager,
    filters,
    as,
    date,
    filterStr,
    filterAsQuery,
    filterParamStr,
    filterQueryStr,
  };
}

export const FiltersProvider = props => {
  const { asPath, pathname, push } = useRouter();

  const [state, dispatch] = useReducer(reducer, { asPath, pathname }, _initReducer);

  useEffect(() => {
    dispatch(() => _initReducer({ asPath, pathname }));
  }, [asPath, pathname]);

  const actions = useMemo(
    () => ({
      setFilter: filter => {
        const { as, filtersManager: manager } = state;
        manager.setFilter(filter);
        const newPath = manager.toPath();
        const hasCatchAll = as.includes('/all') || manager.hasFilterParam() || manager.hasChannel();

        push(`${as}${hasCatchAll ? '/[...params]' : ''}`, newPath, {
          shallow: true,
        });
      },
      dispatch,
    }),
    [dispatch, state]
  );

  return (
    <FiltersContextActions.Provider value={actions}>
      <FiltersContextState.Provider value={state} {...props} />
    </FiltersContextActions.Provider>
  );
};

export function useFiltersState() {
  const context = useContext(FiltersContextState);
  if (context instanceof Error) throw context;
  return context;
}

export function useFiltersActions() {
  const context = useContext(FiltersContextActions);
  if (context instanceof Error) throw context;
  return context;
}

export function useFilters() {
  return [useFiltersState(), useFiltersActions()];
}
