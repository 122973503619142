const defaultFollowed = {
  bars: [],
  clubs: [],
  events: [],
  artists: [],
  restaurants: [],
};

export class Followed {
  constructor(followed = defaultFollowed) {
    Object.assign(this, Followed.parse({ ...defaultFollowed, ...followed }));
  }

  static parse(followed) {
    return Object.entries(followed).reduce(
      (saved, [entityType, entitySaved]) => ({
        ...saved,
        [entityType]: new Set(entitySaved),
      }),
      {}
    );
  }
}
