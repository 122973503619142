export const CLUB_SIMPLE = {
  id: '7ceeee30-919c-11e9-846c-06b12363b88e',
  legacyId: 3052,
  name: 'Phi Beach',
  slug: 'phi-beach',
  coverUrl: 'https://images.xceed.me/clubs/covers/phi-beach-club-porto-cervo-xceed.png',
  city: {
    name: 'Porto Cervo',
    slug: 'porto-cervo',
    timezone: 'Europe/Rome',
  },
};
