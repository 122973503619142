export function getActions(dispatch) {
  function resetCheckout() {
    dispatch({ type: 'resetCheckout' });
  }

  async function addProduct(product) {
    dispatch({ type: 'addProduct', payload: product });
  }

  function addParticipants(participants) {
    dispatch({ type: 'addParticipants', payload: participants });
  }

  function addAddOns(addOns) {
    dispatch({ type: 'addAddOns', payload: addOns });
  }

  function addBookingId(bookingId) {
    dispatch({ type: 'addBookingId', payload: bookingId });
  }

  function changeQty(newQty, productId) {
    dispatch({ type: 'changeQty', payload: { newQty, productId } });
  }

  function setSubmit(submit) {
    dispatch({ type: 'setSubmit', payload: submit });
  }

  function setIsPaymentFormSubmitted(isPaymentFormSubmitted) {
    dispatch({ type: 'setIsPaymentFormSubmitted', payload: isPaymentFormSubmitted });
  }

  function addPaymentResult(paymentResult) {
    dispatch({ type: 'addPaymentResult', payload: paymentResult });
  }

  function setSelectedArea(area) {
    dispatch({ type: 'setSelectedArea', payload: area });
  }

  function removeSelectedArea() {
    dispatch({ type: 'removeSelectedArea' });
  }

  function setMarketingConditionsAccepted(marketingConditionAccepted) {
    dispatch({ type: 'setMarketingConditionsAccepted', payload: marketingConditionAccepted });
  }

  function setApp18voucher(app18Voucher) {
    dispatch({ type: 'setApp18voucher', payload: app18Voucher });
  }

  function removeApp18voucher() {
    dispatch({ type: 'removeApp18voucher' });
  }

  return {
    resetCheckout,
    addProduct,
    addParticipants,
    addBookingId,
    changeQty,
    setSubmit,
    setIsPaymentFormSubmitted,
    addPaymentResult,
    setMarketingConditionsAccepted,
    addAddOns,
    setApp18voucher,
    removeApp18voucher,
    setSelectedArea,
    removeSelectedArea,
  };
}
