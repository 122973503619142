import ScriptCache from './scriptCache';

const initFacebookSDK = () => {
  if (!window.fbAsyncInit) {
    window.fbAsyncInit = function fbAsyncInit() {
      window.FB.init({
        appId: process.env.NEXT_PUBLIC_FB_APP_ID,
        autoLogAppEvents: false,
        xfbml: true,
        version: 'v16.0',
      });
    };
  }
};

async function installScripts() {
  await ScriptCache.load([
    {
      type: 'script',
      url: 'https://connect.facebook.net/en_US/sdk.js',
    },
  ]);
  initFacebookSDK();
}

export default installScripts;
