const defaultArtist = {
  id: null,
  legacyId: null,
  name: null,
  slug: null,
  description: null,
  coverUrl: null,
  followersCount: null,
  isGeneric: false,
  tracks: [],
  musicGenres: [],
  followers: [],
};

export class Artist {
  constructor(artist = defaultArtist) {
    Object.assign(this, { ...defaultArtist, ...artist });
  }
}
