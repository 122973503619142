import React, { useContext, useMemo, useState } from 'react';

import GoogleTagManager from 'services/GoogleTagManager';

const AudioContext = React.createContext();
AudioContext.displayName = 'AudioContext';

export const AudioProvider = props => {
  const [audioId, setAudioId] = useState(null);

  const stopAudio = () => setAudioId(null);
  const toggleAudio = id => {
    if (id !== audioId) GoogleTagManager.playSpotify();
    setAudioId(prevId => (id !== prevId ? id : null));
  };

  const context = useMemo(() => ({ audioId, stopAudio, toggleAudio }), [audioId]);
  return <AudioContext.Provider value={context} {...props} />;
};

export function useAudio() {
  const context = useContext(AudioContext);
  if (!context) throw new Error('useAudio must be used within AudioProvider!');
  return context;
}
