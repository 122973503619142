function _find(collection, predicate) {
  return [...collection].find(predicate);
}
class ScriptCache {
  load(scripts) {
    const loadedScripts = scripts.map(this.loadSrc);
    return Promise.all(loadedScripts);
  }

  loadSrc = tag => {
    const doc = typeof window !== 'undefined' && window.document;

    if (tag.type === 'script' && _find(doc.scripts, ({ src }) => src === tag.url)) {
      return Promise.resolve();
    }
    if (_find(doc.styleSheets, ({ href }) => href === tag.url)) return Promise.resolve();

    return this.createTag(tag);
  };

  createTag = ({ type, url, rel }) => {
    return new Promise((resolve, reject) => {
      const body = document.querySelector('body');
      const tag = document.createElement(type);
      tag.crossorigin = 'anonymous';

      if (type === 'script') {
        tag.type = 'text/javascript';
        tag.src = url;
      } else {
        tag.rel = rel;
        tag.href = url;
      }

      tag.addEventListener('load', resolve);
      tag.addEventListener('error', reject);
      body.appendChild(tag);
      return tag;
    });
  };
}

export default new ScriptCache();
