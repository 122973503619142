import React, { useEffect, useRef } from 'react';
import { animated, useTransition } from '@react-spring/web';
import Cookies from 'js-cookie';
import Router from 'next/router';

import { Caption, Grid, useBoolean, useClickOutside } from '@xceedsrl/jukebox';

import { useTranslation } from 'i18n/';

import CloseButton from './CloseButton';
import CustomLink from './CustomLink';

const Message = animated(Grid);

const transitionConfig = {
  from: { opacity: 0 },
  enter: { opacity: 0.9 },
  leave: { opacity: 0 },
};

function setNoticeCookie() {
  Cookies.set('notice', true, {
    expires: 3650,
  });
}

function CookiePopup() {
  const ref = useRef();
  const { t } = useTranslation();
  const { value, toggle, off } = useBoolean(false);
  useClickOutside(ref, off, 'mousedown');

  const notice = Cookies.get('notice');

  const transitions = useTransition(value, transitionConfig);

  useEffect(() => {
    if (!notice) {
      Router.events.on('routeChangeComplete', off);
      setNoticeCookie();
      toggle(!notice);
    } else {
      Router.events.off('routeChangeComplete', off);
    }
  }, [notice]);

  return transitions(
    (style, item) =>
      item && (
        <Message
          ref={ref}
          top={[70, 80]}
          left={10}
          right={10}
          padding={2}
          gridGap={2}
          position="fixed"
          overflow="hidden"
          boxShadow={1}
          borderRadius={3}
          backgroundColor="brandLight"
          gridTemplateColumns="1fr auto"
          style={style}
          zIndex={2}
        >
          <Caption fontSize={[0, 1]} lineHeight={[0, 1]} color="brandDark">
            {t('common:cookieAlert')}
            <CustomLink as="/cookies-policy" href="/cookies-policy">
              {t('common:cookiePolicy')}
            </CustomLink>
            .
          </Caption>
          <CloseButton onClick={off} fontSize={2} />
        </Message>
      )
  );
}

export default CookiePopup;
