/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import Router from 'next/router';

import getDefaultLang from './helpers/getDefaultLang';
import getLocaleFromLang from './helpers/getLocaleFromLang';
import getPageNamespaces from './helpers/getPageNamespaces';
import I18nProvider from './I18nProvider';

export function getLang(ctx, config) {
  const { query, req } = ctx;
  const urlLang = query.lang;

  const isQueryLangValid = config.allLanguages.some(l => urlLang === l);
  return isQueryLangValid ? urlLang : getDefaultLang(req, config);
}

export default function appWithI18n(AppToTranslate, config = {}) {
  function AppWithTranslations(props) {
    const { lang, locale, namespaces } = props;

    // On mount check if the browser language is the same as the one defined by URL and change it accordingly
    useEffect(() => {
      const defaultLang = getDefaultLang(null, config);
      const browserLang =
        (navigator.languages && navigator.languages[0].split('-')[0]) ||
        navigator.language.split('-')[0];
      const isBrowserLangValid = config.allLanguages.some(l => browserLang === l);
      if (
        isBrowserLangValid &&
        lang !== browserLang &&
        lang === defaultLang &&
        !Router.asPath.startsWith(`/${defaultLang}`)
      ) {
        const isRoot = Router.asPath === '/';
        const editedAsPath = Router.asPath.startsWith(`/${defaultLang}`)
          ? Router.asPath.replace(`${defaultLang}`, `${browserLang}`)
          : `/${browserLang}${isRoot ? '' : Router.asPath}`;

        Router.replace(Router.pathname, editedAsPath);
      }
    }, []);

    return (
      <I18nProvider lang={lang} locale={locale} namespaces={namespaces}>
        <AppToTranslate {...props} />
      </I18nProvider>
    );
  }

  AppWithTranslations.getInitialProps = async ({ Component, ctx }) => {
    const lang = getLang(ctx, config);
    const locale = getLocaleFromLang(lang, config);
    let appProps = { pageProps: {} };

    if (AppToTranslate.getInitialProps) {
      appProps =
        (await AppToTranslate.getInitialProps({
          Component,
          ctx,
          lang,
        })) || {};
    }
    const page = ctx.pathname;
    const namespaces = await getPageNamespaces(config, page, ctx);
    const pageNamespaces = await Promise.all(
      namespaces.map(ns =>
        typeof config.loadLocaleFrom === 'function'
          ? config.loadLocaleFrom(lang, ns)
          : Promise.resolve([])
      )
    );

    return {
      ...appProps,
      lang,
      locale,
      namespaces: namespaces.reduce((obj, ns, i) => {
        obj[ns] = pageNamespaces[i];
        return obj;
      }, {}),
    };
  };

  return AppWithTranslations;
}
