import winston from 'winston';

import { LOGSTASH_HOST, LOGSTASH_PORT } from 'constants/logstash';
import { IS_PROD_NODE_ENV } from 'constants/nodeEnv';

const transports = require('winston-logstash');

export const loggerToFile = new winston.Logger({
  transports: [
    new winston.transports.Console(),
    new winston.transports.File({ filename: 'logs/app.log' }),
  ],
});

export const loggerToLogstash = new winston.Logger({
  transports: [
    new winston.transports.Console(),
    new transports.Logstash({
      name: 'b2c',
      port: LOGSTASH_PORT,
      node_name: 'b2c',
      host: LOGSTASH_HOST,
      max_connect_retries: -1,
    }),
  ],
});

export class Logger {
  static error(err) {
    let errorToLog = err.message;
    if (IS_PROD_NODE_ENV) {
      errorToLog = JSON.stringify({
        level: 'error',
        message: err.message,
        ...(err.name && { name: err.name }),
        ...(err.requestUrl && { requestUrl: err.requestUrl }),
        ...(err.statusCode && { statusCode: err.statusCode }),
      });
    }
    return console.error(errorToLog);
  }
}
