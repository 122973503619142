import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { animated, useTransition } from '@react-spring/web';
import styled from 'styled-components';

import { Alert, Box, Cross, Flex, Grid, Icon } from '@xceedsrl/jukebox';

const Container = styled(Flex)`
  position: fixed;
  z-index: 10000;
  left: 30px;
  right: 30px;
  bottom: 30px;
  flex-direction: column;
  pointer-events: none;
`;

const Content = Grid;
const Message = animated(Box);

const CloseIcon = styled(Icon)`
  cursor: pointer;
  pointer-events: all;
`;

let id = 0;

const backgroundPalette = {
  success: 'feeling.positive',
  error: 'feeling.negative',
  info: 'feeling.temporary',
};

function config(_, state) {
  const baseConfig = { tension: 125, friction: 20, precision: 0.1 };
  return state === 'leave' ? [{ duration: 300 }, baseConfig, baseConfig] : baseConfig;
}

function computeReadingTime(msg) {
  const wordsCount = msg.split(' ').length;
  const minReadTime = 3000;
  const wordsPerSecond = 3;
  const timeToSeePopup = 300;
  const readingTime = timeToSeePopup + (wordsCount / wordsPerSecond) * 1000;
  return Math.max(minReadTime, readingTime);
}

function NotificationHub({ children }) {
  const isServer = typeof window === 'undefined';
  const [refMap] = useState(() => new WeakMap());
  const [cancelMap] = useState(() => new WeakMap());
  const [items, setItems] = useState([]);
  const transitions = useTransition(items, {
    from: { marginBottom: 10, opacity: 0, height: 0 },
    enter: item => next => next({ opacity: 1, height: refMap.get(item)?.offsetHeight }),
    leave: _ => async next => {
      await next({ opacity: 0 });
      await next({ height: 0, marginBottom: 0 });
    },
    onRest: (_, { item }) => {
      const duration = computeReadingTime(item.msg);
      const remove = () => setItems(state => state.filter(i => i.key !== item.key));
      cancelMap.set(item, remove);
      setTimeout(remove, duration);
    },
    config,
  });

  useEffect(
    () => void children(({ msg, type }) => setItems(state => [...state, { key: id++, msg, type }])),
    []
  );

  if (isServer) return null;
  return ReactDOM.createPortal(
    <Container id="notification-hub" alignItems={['center', 'center', 'flex-start']}>
      {transitions((style, item) => (
        <Message
          style={style}
          width={[1, '40ch']}
          boxShadow={1}
          borderRadius={3}
          position="relative"
          overflow="hidden"
        >
          <Content
            ref={ref => ref && refMap.set(item, ref)}
            backgroundColor={backgroundPalette[item.type]}
            gridTemplateColumns="1fr auto"
            gridGap={2}
            paddingX={3}
            paddingY={2}
          >
            <Alert fontSize={3} color="brandLight">
              {item.msg}
            </Alert>
            <CloseIcon
              fontSize={14}
              color="brandLight"
              onClick={e => {
                e.stopPropagation();
                if (cancelMap.has(item)) cancelMap.get(item)();
              }}
            >
              <Cross />
            </CloseIcon>
          </Content>
        </Message>
      ))}
    </Container>,
    document.body
  );
}

export default NotificationHub;
