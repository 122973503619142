import { City } from 'models/City';

const defaultBar = {
  id: null,
  name: null,
  about: null,
  slug: null,
  coverUrl: null,
  logoUrl: null,
  capacity: null,
  priceLevel: null,
  rating: null,
  phone: null,
  email: null,
  address: null,
  coordinates: {},
  city: new City(),
  followersCount: null,
  openingHours: [],
  isOpenNow: false,
  musicGenres: [],
  vibes: [],
  facilities: [],
  foodType: [],
  characteristics: [],
  socialMedia: [],
  followers: [],
  gallery: [],
  reviews: [],
};

export class Bar {
  constructor(bar = defaultBar) {
    Object.assign(this, Bar.parse({ ...defaultBar, ...bar }));
  }

  static parse(bar) {
    const { city, coverUrl, gallery } = bar;
    return {
      ...bar,
      city: new City(city),
      gallery: [{ id: 'gallery-cover', imageUrl: coverUrl }, ...gallery],
    };
  }
}
