import React from 'react';

export function Compose({ components, children }) {
  return components.reduceRight((acc, CompWithProps) => {
    if (Array.isArray(CompWithProps)) {
      const [Comp, props] = CompWithProps;
      return <Comp {...props}>{acc}</Comp>;
    }
    return <CompWithProps>{acc}</CompWithProps>;
  }, children);
}
