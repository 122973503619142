import Link from 'next/link';
import PropTypes from 'prop-types';

import { Box, ChevronLeft, Flex, Icon } from '@xceedsrl/jukebox';

const BackButtonHeader = ({ redirectUrl }) => {
  return (
    <Flex alignItems="center" postition="relative">
      <Box position="absolute" top="10px" left="10px">
        <Link href={redirectUrl} as={redirectUrl} aria-label="Profile">
          <Icon color="#000000" isPointer fontSize={20}>
            <ChevronLeft />
          </Icon>
        </Link>
      </Box>
    </Flex>
  );
};

export default BackButtonHeader;

BackButtonHeader.propTypes = {
  redirectUrl: PropTypes.string.isRequired,
};
