const bots = [
  // generic
  'bot', // googlebot, bingbot, telegrambot, twitterbot, yandexbot, etc.
  'crawler',
  'monitor', // monitor & monitoring
  'spider', // baiduspider, 360spider, screaming frog seo spider, etc.

  // search engines
  'google',
  'qwantify',
  'yahoo',
  'bingpreview',

  // secondary Google bots
  'Google Favicon',
  'Google Page Speed Insights',
  'Google Web Preview',
  'Google Wireless Transcoder',
  'Google-PhysicalWeb',
  'Google-Site-Verification',
  'Google-Structured-Data-Testing-Tool',
  'google-xrawler',
  'GoogleImageProxy',

  // aggregators, messengers and social networks
  'facebookexternalhit',
  'flipboard',
  'tumblr',
  'vkshare',
  'whatsapp',
  'telegrambot',
  'twitterbot',
];

const createRegex = () => new RegExp(`(${bots.join('|')})`, 'i');
const isBotRegex = createRegex();

export const isBot = userAgent => isBotRegex.test(userAgent);
