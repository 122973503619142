export const RESTAURANT_SIMPLE = {
  id: '1b3a944a-d7ee-11ea-acdd-0242ac110004',
  name: 'Arcano',
  slug: 'arcano',
  coverUrl: 'https://images.xceed.me/restaurants/covers/arcano-restaurant-barcelona-xceedec3a.jpeg',
  city: {
    name: 'Barcelona',
    slug: 'barcelona',
    timezone: 'Europe/Madrid',
  },
};
