const defaultCountry = {
  id: null,
  name: null,
  slug: null,
  coverUrl: null,
  isoCode: null,
  coordinates: {},
  settings: {
    showArtists: false,
    showBars: false,
    showClubs: false,
    showEvents: false,
    showRestaurants: false,
  },
  guide: [],
};

export class Country {
  constructor(country = defaultCountry) {
    Object.assign(this, { ...defaultCountry, ...country });
  }
}
