function flat(a) {
  return a.reduce((b, c) => b.concat(c), []);
}

/**
 * Get page namespaces
 *
 * @param {object} config
 * @param {string} page
 */
export default async function getPageNamespaces({ pages = {} }, page, ctx) {
  const rgx = 'rgx:';
  const getNs = async ns => (typeof ns === 'function' ? ns(ctx) : ns || []);
  let formatedPage;
  // Remove the [lang] parameters from pathname to simplify how namespaces are declared in config.js
  if (page.includes('[lang]') && page.endsWith('[lang]')) {
    formatedPage = '/';
  } else if (page.includes('[lang]') && !page.endsWith('[lang]')) {
    [, formatedPage] = page.split('[lang]');
  } else {
    formatedPage = page;
  }

  // Namespaces promises using regex
  const rgxs = Object.keys(pages).reduce((arr, p) => {
    if (p.substring(0, rgx.length) === rgx && new RegExp(p.replace(rgx, '')).test(formatedPage)) {
      arr.push(getNs(pages[p]));
    }
    return arr;
  }, []);

  return [
    ...(await getNs(pages['*'])),
    ...(await getNs(pages[formatedPage])),
    ...flat(await Promise.all(rgxs)),
  ];
}
