const defaultPromoter = {
  id: null,
  name: null,
  email: null,
  slug: null,
  link: null,
  description: null,
  facebookPixel: null,
  imageName: null,
  image: null,
  avatar: null,
  isStaff: null,
  isVirtual: null,
  isActive: null,
};

export class Promoter {
  constructor(promoter = defaultPromoter) {
    Object.assign(this, { ...defaultPromoter, ...promoter });
  }
}
