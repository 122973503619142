import React from 'react';
import Link from 'next/link';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { flexbox, layout, space, color } from 'styled-system';

import { useFiltersState } from 'store/filters';
import { useStaticData } from 'store/staticData';
import { hasUrlProtocol } from 'utils/regExp';

import { useTranslation } from '../i18n';

const ExternalLink = styled.a`
  ${flexbox}
  ${layout}
  ${space}
  outline: none;
  ${color}
`;

const InnerLink = ExternalLink;

function makeDefaultLink(path, isDefault) {
  if (path !== '/') return path;
  return isDefault ? '/' : '';
}

const CustomLink = props => {
  const { children, href, as, onClick, channel, download, withFilters, openInNewTab, ...rest } =
    props;
  const { lang } = useTranslation();
  const { isDefaultSite } = useStaticData();
  const { filterStr } = useFiltersState();

  const isExternal = hasUrlProtocol(href) || download;

  const nextPath = `${isDefaultSite ? '' : `/${lang}`}${makeDefaultLink(as, isDefaultSite)}${
    channel && `/channel--${channel}`
  }${withFilters ? `/${filterStr}` : ''}`;

  return isExternal ? (
    <ExternalLink
      href={href}
      onClick={onClick}
      onKeyDown={null}
      role="link"
      target="_blank"
      rel="noopener noreferrer"
      tabIndex={0}
      {...rest}
    >
      {children}
    </ExternalLink>
  ) : (
    <Link
      href={`${isDefaultSite ? '' : '/[lang]'}${makeDefaultLink(href, isDefaultSite)}`}
      as={nextPath}
      passHref
    >
      <InnerLink
        onClick={onClick}
        onKeyDown={null}
        role="link"
        tabIndex={0}
        target={openInNewTab ? '_blank' : ''}
        rel={openInNewTab ? 'noopener noreferrer' : ''}
        {...rest}
      >
        {children}
      </InnerLink>
    </Link>
  );
};

export default CustomLink;

CustomLink.defaultProps = {
  onClick: null,
  channel: '',
  download: false,
  as: null,
  children: null,
  withFilters: false,
  openInNewTab: false,
};

CustomLink.propTypes = {
  children: PropTypes.node,
  href: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  channel: PropTypes.string,
  download: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  as: PropTypes.string,
  withFilters: PropTypes.bool,
  openInNewTab: PropTypes.bool,
};
