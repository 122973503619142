const OMIT = ['castelnau-de-montmiral', 'chalon-sur-saone'];

export function cleanSlug(text) {
  if (typeof text !== 'string') throw new Error('Text must be a string');
  return OMIT.includes(text) ? text : text.replace(/-/g, ' ');
}

export const formatPrice = (value, currency, locale) => {
  return value.toLocaleString(locale, {
    style: 'currency',
    localeMatcher: 'best fit',
    currency: currency || 'EUR',
  });
};

export function cleanAsPath(asPath, lang) {
  if (asPath.includes(`/${lang}/`)) {
    const noLang = `/${asPath.split(`/${lang}/`).pop()}`;
    return noLang.endsWith('/') ? noLang.slice(0, -1) : noLang;
  }
  if (asPath.endsWith(`/${lang}`)) {
    return '';
  }
  return asPath.endsWith('/') ? asPath.slice(0, -1) : asPath;
}

export function stripHTML(text) {
  const regex = /(<([^>]+)>)/gi;
  return text.replace(regex, '');
}
