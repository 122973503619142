import Router from 'next/router';
import NProgress from 'nprogress';
import PropTypes from 'prop-types';

import { useSharedDataState } from 'store/sharedData';

let timeout;

const start = () => {
  timeout = setTimeout(NProgress.start, 100);
};

const done = () => {
  clearTimeout(timeout);
  NProgress.done();
};

Router.events.on('routeChangeStart', start);
Router.events.on('routeChangeComplete', done);
Router.events.on('routeChangeError', done);

const NProgressIndicator = ({ componentTheme: { navbar } }) => {
  const state = useSharedDataState();
  const theme = navbar?.theme || state.navbar.theme;
  return (
    <style jsx global>
      {`
        /* Make clicks pass-through */
        #nprogress {
          pointer-events: none;
        }
        #nprogress .bar {
          background: ${theme === 'light' ? '#3b88fd' : '#fff'};
          position: fixed;
          z-index: 1031;
          top: 0;
          left: 0;
          width: 100%;
          height: 4px;
        }
        /* Fancy blur effect */
        #nprogress .peg {
          display: block;
          position: absolute;
          right: 0px;
          width: 100px;
          height: 100%;
          box-shadow: ${theme === 'light'
            ? '0 0 10px #9BC5FC, 0 0 5px #9BC5FC'
            : '0 0 10px #F7F7F7, 0 0 5px #F7F7F7'};
          opacity: 1;
          -webkit-transform: rotate(3deg) translate(0px, -6px);
          -ms-transform: rotate(3deg) translate(0px, -6px);
          transform: rotate(3deg) translate(0px, -6px);
        }
      `}
    </style>
  );
};

export default NProgressIndicator;

NProgressIndicator.propTypes = {
  componentTheme: PropTypes.shape({
    navbar: PropTypes.shape({
      theme: PropTypes.string,
      backgroundColor: PropTypes.string,
    }),
  }),
};
NProgressIndicator.defaultProps = {
  componentTheme: {},
};
