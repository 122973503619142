export const CITY_SIMPLE = {
  id: '066eb0c4-1035-11ea-b000-06b880e08c2c',
  legacyId: 256,
  name: 'Porto Cervo',
  slug: 'porto-cervo',
  coverUrl: 'https://images.xceed.me/cities/covers/porto-cervo-events-xceed.jpg',
  timezone: 'Europe/Rome',
  country: {
    name: 'Italy',
  },
};
