export const ARTIST_SIMPLE = {
  id: 'cde1f965-480d-11ea-b954-06b880e08c2c',
  legacyId: 566,
  name: 'Nina Kraviz',
  slug: 'nina-kraviz',
  coverUrl: 'https://i.scdn.co/image/54f1aa3b666f6389172d433d6fcb9704b105ded2',
  tracks: [
    {
      name: 'Ghetto Kraviz - Original Mix',
      url: 'https://p.scdn.co/mp3-preview/9fdfdbb3036230053e4a76ef6767f16dc783651c?cid=26fa853e56704f3a820c6f7ec8d59212',
    },
  ],
  musicGenres: [
    {
      name: 'electra',
    },
    {
      name: 'minimal techno',
    },
    {
      name: 'russian electronic',
    },
    {
      name: 'russian techno',
    },
  ],
};
