import { getTrackingAddOns } from 'utils/get-tracking-addons';

const getItems = (itemOffer, offer, selectedAddOns, channel) => {
  const items = [
    {
      ...itemOffer,
      quantity: offer?.bookingQuantity,
    },
  ];
  if (selectedAddOns)
    return [...items, ...getTrackingAddOns(selectedAddOns, offer.addOns, channel)];
  return items;
};

const formatEventData = ({ offer, event, shoppingCart, channel, bookingId }) => {
  const affiliation = channel ?? 'xceed-web';
  const itemOffer = {
    item_id: event.id,
    item_name: event.name,
    affiliation,
    item_brand: offer.id,
    item_category: offer.admissionType,
    item_variant: offer.name,
    price: offer?.price.amount,
    currency: offer.price.currency,
    quantity: offer?.bookingQuantity,
  };

  const eventData = {
    ...(!!bookingId && { transaction_id: bookingId }),
    affiliation,
    currency: offer.price.currency,
    city: event.venue.city.name,
    venue: event.venue.name,
    value: Math.trunc(shoppingCart.total * 100) / 100,
    shipping: shoppingCart.totalFee,
    items: offer?.bookingQuantity
      ? getItems(itemOffer, offer, shoppingCart?.selectedAddOns, channel)
      : itemOffer,
  };
  return eventData;
};

class GoogleTagManager {
  removeVariables = () => {
    window.dataLayer.push({ ecommerce: undefined, user_data: undefined, data: undefined });
  };

  viewItem(props) {
    this.removeVariables();
    const ecommerce = formatEventData(props);
    window.dataLayer.push({ event: 'view_item', ecommerce });
  }

  selectTableOffer(data) {
    this.removeVariables();
    window.dataLayer.push({ event: 'select_table_offer', ecommerce: data });
  }

  addToCart(props) {
    this.removeVariables();
    const ecommerce = formatEventData(props);
    window.dataLayer.push({ event: 'add_to_cart', ecommerce });
  }

  addOns(props) {
    this.removeVariables();
    const ecommerce = formatEventData(props);
    window.dataLayer.push({ event: 'add_ons', ecommerce });
  }

  beginCheckout(props) {
    this.removeVariables();
    const ecommerce = formatEventData(props);
    window.dataLayer.push({
      event: 'begin_checkout',
      ecommerce,
      ...(props?.userData, { ...props.userData }),
    });
  }

  payment(props) {
    window.dataLayer.push({ ecommerce: undefined });
    const ecommerce = formatEventData(props);
    window.dataLayer.push({ event: 'payment', ecommerce });
  }

  purchase(props) {
    window.dataLayer.push({ ecommerce: undefined });
    const ecommerce = formatEventData(props);
    window.dataLayer.push({ event: 'purchase', ecommerce });
  }

  selectFilterStart(data) {
    this.removeVariables();
    window.dataLayer.push({ event: 'select_filter_start', data });
  }

  selectFilter(data) {
    this.removeVariables();
    window.dataLayer.push({ event: 'select_filter', data });
  }

  searchTopbar() {
    this.removeVariables();
    window.dataLayer.push({ event: 'search_topbar' });
  }

  searchBody() {
    this.removeVariables();
    window.dataLayer.push({ event: 'search_body' });
  }

  listEventTopbar() {
    this.removeVariables();
    window.dataLayer.push({ event: 'listevent_topbar' });
  }

  loginTopbar() {
    this.removeVariables();
    window.dataLayer.push({ event: 'login_topbar' });
  }

  discoverTopbar() {
    this.removeVariables();
    window.dataLayer.push({ event: 'discover_topbar' });
  }

  continueWithFacebook() {
    this.removeVariables();
    window.dataLayer.push({ event: 'continue_with_facebook' });
  }

  loginWithEmailStart() {
    this.removeVariables();
    window.dataLayer.push({ event: 'login_email_start' });
  }

  signupWithEmailStart() {
    this.removeVariables();
    window.dataLayer.push({ event: 'lignup_email_star' });
  }

  signupWithEmailProgress() {
    this.removeVariables();
    window.dataLayer.push({ event: 'signup_email_progress' });
  }

  authSuccess(data) {
    this.removeVariables();
    window.dataLayer.push({ event: 'login_success', data });
  }

  playSpotify() {
    this.removeVariables();
    window.dataLayer.push({ event: 'play_spotify' });
  }

  share(data) {
    this.removeVariables();
    window.dataLayer.push({ event: 'share', data });
  }

  shareNative = () => {
    this.share({ method: 'native' });
  };

  addToFavourite(data) {
    this.removeVariables();
    window.dataLayer.push({ event: 'add_to_favourite', data });
  }

  sendWebVitalsMetrics({ name, value, id }) {
    const data = {
      event_category: 'Web Vitals',
      event_action: name,
      // Google Analytics metrics must be integers, so the value is rounded.
      // For CLS the value is first multiplied by 1000 for greater precision
      // (note: increase the multiplier for greater precision if needed).
      event_value: Math.round(name === 'CLS' ? value * 1000 : value),
      // The `id` value will be unique to the current page load. When sending
      // multiple values from the same page (e.g. for CLS), Google Analytics can
      // compute a total by grouping on this ID (note: requires `eventLabel` to
      // be a dimension in your report).
      event_label: id,
    };
    window.dataLayer.push({ event: 'web-witals', data });
  }

  ctaButtonBar(data) {
    this.removeVariables();
    window.dataLayer.push({ event: 'book_now', data });
  }

  ctaButtonRestaurant(data) {
    this.removeVariables();
    window.dataLayer.push({ event: 'book_now_restaurant', data });
  }

  ctaBusiness(data) {
    this.removeVariables();
    window.dataLayer.push({ event: 'business_call_to_action', data });
  }

  ctaPricingStart(data) {
    this.removeVariables();
    window.dataLayer.push({ event: 'pricing_page_start_now', data });
  }

  ctaPricingContact(data) {
    this.removeVariables();
    window.dataLayer.push({ event: 'pricing_page_contact_us', data });
  }
}

export default new GoogleTagManager();
