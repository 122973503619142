export const BARS_IN_A_LIST = [
  {
    id: '083844f8-af1e-11ea-9a06-0242ac110004',
    name: 'Mr. Robinson',
    slug: 'mr-robinson',
    coverUrl: 'https://images.xceed.me/bars/covers/mr-robinson-club-barcelona-xceed4798.jpeg',
    priceLevel: 3,
    rating: 4.3,
    coordinates: {
      latitude: 41.382831,
      longitude: 2.18237,
    },
    city: {
      id: '066b6cbb-1035-11ea-b000-06b880e08c2c',
      legacyId: 44,
      name: 'Barcelona',
      slug: 'barcelona',
      coverUrl: 'https://images.xceed.me/cities/covers/barcelona-events-xceed.jpg',
      timezone: 'Europe/Madrid',
    },
  },
  {
    id: 'f478b226-af1e-11ea-9b96-0242ac11000a',
    name: 'Guzzo',
    slug: 'guzzo',
    coverUrl: 'https://images.xceed.me/bars/covers/guzzo-club-barcelona-xceede29d.jpeg',
    priceLevel: 3,
    rating: 4.3,
    coordinates: {
      latitude: 41.3851,
      longitude: 2.181456,
    },
    city: {
      id: '066b6cbb-1035-11ea-b000-06b880e08c2c',
      legacyId: 44,
      name: 'Barcelona',
      slug: 'barcelona',
      coverUrl: 'https://images.xceed.me/cities/covers/barcelona-events-xceed.jpg',
      timezone: 'Europe/Madrid',
    },
  },
  {
    id: 'd8e857d6-af1f-11ea-a153-0242ac11000a',
    name: 'Oca\u00f1a',
    slug: 'ocana',
    coverUrl: 'https://images.xceed.me/bars/covers/ocana-club-barcelona-xceede38f.jpeg',
    priceLevel: 3,
    rating: 4,
    coordinates: {
      latitude: 41.379834,
      longitude: 2.173526,
    },
    city: {
      id: '066b6cbb-1035-11ea-b000-06b880e08c2c',
      legacyId: 44,
      name: 'Barcelona',
      slug: 'barcelona',
      coverUrl: 'https://images.xceed.me/cities/covers/barcelona-events-xceed.jpg',
      timezone: 'Europe/Madrid',
    },
  },
  {
    id: '9ba834ce-af1d-11ea-87dd-0242ac11000a',
    name: 'Can Codina',
    slug: 'can-codina',
    coverUrl: 'https://images.xceed.me/bars/covers/can-codina-club-barcelona-xceeda71b.jpeg',
    priceLevel: 2,
    rating: 4.1,
    coordinates: {
      latitude: 41.398937,
      longitude: 2.160851,
    },
    city: {
      id: '066b6cbb-1035-11ea-b000-06b880e08c2c',
      legacyId: 44,
      name: 'Barcelona',
      slug: 'barcelona',
      coverUrl: 'https://images.xceed.me/cities/covers/barcelona-events-xceed.jpg',
      timezone: 'Europe/Madrid',
    },
  },
  {
    id: '9510a94e-afb7-11ea-8017-0242ac11000a',
    name: 'Mesclad\u00eds del Pou',
    slug: 'mescladis-del-pou',
    coverUrl: 'https://images.xceed.me/bars/covers/mescladis-del-pou-club-barcelona-xceeda920.jpeg',
    priceLevel: 2,
    rating: 4.4,
    coordinates: {
      latitude: 41.387076,
      longitude: 2.179049,
    },
    city: {
      id: '066b6cbb-1035-11ea-b000-06b880e08c2c',
      legacyId: 44,
      name: 'Barcelona',
      slug: 'barcelona',
      coverUrl: 'https://images.xceed.me/cities/covers/barcelona-events-xceed.jpg',
      timezone: 'Europe/Madrid',
    },
  },
];

export const BAR_SIMPLE = BARS_IN_A_LIST[0];
