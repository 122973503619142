import { Logger } from 'services/Logger';

import AppError from './AppError';

export default class PageNotFoundError extends AppError {
  constructor(message, requestUrl) {
    super(message, 404);
    this.title = "Looks like this page doesn't exist.";
    this.name = 'PageNotFoundError';
    this.code = 'ENOENT';
    this.requestUrl = requestUrl;
    this.shouldNotReportToSentry = true;
    Logger.error(this);
  }
}
