import React from 'react';
import { useRouter } from 'next/router';

import { Selector } from '@xceedsrl/jukebox';

import hosts from 'services/apiClient/hosts';
import { cleanAsPath } from 'utils/fns';
import { hasLangCode } from 'utils/regExp';

import { allLanguages } from './config';
import useTranslation from './useTranslation';

const labels = {
  ca: 'Català',
  de: 'Deutsch',
  en: 'English',
  es: 'Español',
  fr: 'Français',
  it: 'Italiano',
  pt: 'Português',
};

const LangSwitcher = () => {
  const { lang } = useTranslation();
  const router = useRouter();

  const cleanedAsPath = cleanAsPath(router.asPath, lang);

  function handleLangChange(e) {
    const val = e.target.value;
    const isRoot = router.asPath === '/';
    const hasLang = hasLangCode(router.asPath);

    const newPath = hasLang
      ? `/${val}${router.asPath.slice(3)}`
      : `/${val}${isRoot ? '' : router.asPath}`;
    router.push(newPath);
  }

  return (
    <Selector
      selected={lang}
      onChange={handleLangChange}
      width={125}
      backgroundColor="brandLight"
      itemsInView={3}
    >
      {allLanguages.map(l => (
        <Selector.Option
          href={`${hosts.LEGACY_MONOLITH}/${l}${cleanedAsPath}`}
          role="link"
          tabindex="0"
          as="a"
          key={l}
          label={labels[l]}
          value={l}
          color="brandDark"
        />
      ))}
    </Selector>
  );
};

export default LangSwitcher;
