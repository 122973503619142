export const getTrackingAddOns = (addOnsSelected, offerAddOns, channel) => {
  const items = [];
  offerAddOns.forEach(offerAddOn =>
    addOnsSelected.forEach(addOn => {
      if (addOn.id === offerAddOn.id) {
        items.push({
          item_id: offerAddOn.id,
          item_name: offerAddOn.name,
          affiliation: channel,
          item_category: 'add-on',
          price: addOn.price ?? offerAddOn.fixedPrice.amount,
          currency: offerAddOn.fixedPrice.currency,
          quantity: parseInt(addOn.quantity, 10),
        });
      }
    })
  );
  return items;
};
