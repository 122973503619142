import { Logger } from 'services/Logger';

import AppError from './AppError';

export default class HttpError extends AppError {
  constructor(message, status, path, code) {
    super(message, status);
    this.title = 'An error occurred, try to reload this page.';
    this.name = 'HttpError';
    this.requestUrl = path;
    this.code = code;
    Logger.error(this);
  }
}
