const COUNTRIES = {
  spain: {
    id: '0a75d5ef-1035-11ea-b000-06b880e08c2c',
    name: 'Espa\u00f1a',
    slug: 'spain',
    isoCode: 'ES',
    coverUrl: 'https://images.xceed.me/cities/covers/barcelona-events-xceed.jpg',
    currency: 'EUR',
    timezone: 'Europe/Madrid',
    coordinates: {
      latitude: 41.3833,
      longitude: 2.1833,
    },
    settings: {
      showArtists: true,
      showBars: true,
      showClubs: true,
      showEvents: true,
      showRestaurants: true,
    },
  },
};

export default COUNTRIES;
