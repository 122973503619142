module.exports = {
  filterSchema: {
    events: ['dates', 'venues', 'vibes', 'musicGenres'],
    bars: ['venues', 'vibes', 'musicGenres', 'foodTypes', 'priceLevel', 'facilities', 'openNow'],
    restaurants: [
      'venues',
      'vibes',
      'musicGenres',
      'foodTypes',
      'priceLevel',
      'facilities',
      'openNow',
    ],
    clubs: ['dates', 'venues', 'vibes', 'musicGenres', 'facilities'],
    artists: ['dates', 'vibes', 'musicGenres'],
    city: ['dates', 'venues', 'vibes', 'musicGenres'],
  },
  defaults: {
    venues: [],
    musicGenres: [],
    vibes: [],
    dates: {
      startDate: null,
      endDate: null,
    },
    foodTypes: [],
    priceLevel: [],
    facilities: [],
    openNow: [],
  },
};
