/* eslint-disable import/no-extraneous-dependencies */
import { rest } from 'msw';

import hosts from 'services/apiClient/hosts';

import { ARTIST_SIMPLE } from './data/artists';
import { BAR_SIMPLE, BARS_IN_A_LIST } from './data/bars';
import { CITY_SIMPLE } from './data/cities';
import { CLUB_SIMPLE } from './data/clubs';
import COUNTRIES from './data/countries';
import { EVENT_SIMPLE, EVENT_SIMPLE_DATES, EVENTS_IN_A_LIST } from './data/events';
import { RESTAURANT_SIMPLE } from './data/restaurants';

export const handlers = [
  rest.get(`${hosts.EVENTS_SERVICE}/v1/countries/:country`, (req, res, ctx) => {
    const { country } = req.params;

    return res(
      ctx.json({
        success: true,
        data: COUNTRIES[country],
      })
    );
  }),
  rest.get('**/v1/search', (req, res, ctx) => {
    return res(
      ctx.json({
        success: true,
        data: {
          artists: [ARTIST_SIMPLE],
          bars: [BAR_SIMPLE],
          cities: [CITY_SIMPLE],
          events: [EVENT_SIMPLE],
          restaurants: [RESTAURANT_SIMPLE],
          venues: [CLUB_SIMPLE],
        },
      })
    );
  }),
  rest.get('**/v1/cities/barcelona/bars/categories/**/bars', (req, res, ctx) => {
    const foodTypes = req.url.searchParams.get('foodTypes');
    if (foodTypes) {
      return res(
        ctx.json({
          success: true,
          data: [BAR_SIMPLE],
        })
      );
    }
    return res(
      ctx.json({
        success: true,
        data: BARS_IN_A_LIST,
      })
    );
  }),
  rest.get('**/v1/cities/barcelona/events/categories/**/events', (req, res, ctx) => {
    const venueCharacteristics = req.url.searchParams.get('venueCharacteristics');
    const musicGenres = req.url.searchParams.get('musicGenres');
    const vibes = req.url.searchParams.get('vibes');
    const endTime = req.url.searchParams.get('endTime');
    const startTime = req.url.searchParams.get('startTime');

    if (venueCharacteristics || musicGenres || vibes) {
      return res(
        ctx.json({
          success: true,
          data: [EVENT_SIMPLE],
        })
      );
    }
    if (endTime && startTime) {
      return res(
        ctx.json({
          success: true,
          data: [EVENT_SIMPLE_DATES],
        })
      );
    }
    return res(
      ctx.json({
        success: true,
        data: EVENTS_IN_A_LIST,
      })
    );
  }),
];
