import React, { useContext, useEffect } from 'react';

import { useSharedDataActions } from 'store/sharedData';

const ChannelContext = React.createContext(
  new Error('useChannelState must be used within ChannelContext!')
);

export const ChannelProvider = ChannelContext.Provider;
ChannelContext.displayName = 'ChannelContext';

export function useChannelState() {
  const context = useContext(ChannelContext);
  if (context instanceof Error) throw context;
  return context;
}

export function useChannelLink(hasChannel = true) {
  if (typeof hasChannel !== 'boolean') {
    throw new TypeError(`The first value passed to useChannelLink should be a boolean`);
  }
  const { dispatch } = useSharedDataActions();

  const channel = useChannelState();

  const isChannelLink = hasChannel && channel !== undefined;

  useEffect(() => {
    dispatch({
      shouldShowSearch: !isChannelLink,
      shouldShowCities: !isChannelLink,
    });
  }, [isChannelLink, dispatch]);

  return channel;
}
