import { Promoter } from 'models/Promoter';

import hosts from '../hosts';

export class PromoterController {
  static getInfo = async promoterId => {
    const url = `${hosts.MONOLITH}/promoters/${promoterId}`;
    return fetch(url)
      .then(response => response.json())
      .then(data => new Promoter(data));
  };
}
