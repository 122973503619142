import hosts from '../hosts';
import request from '..';

function _missingScript(script) {
  throw new Error(`The ${script} script is missing, try to add it to the page and retry.`);
}

export class AuthController {
  static signin(credentials) {
    return request({
      path: '/v1/signin',
      method: 'POST',
      body: credentials,
      baseUrl: hosts.LEGACY_MONOLITH,
    });
  }

  static socialSignin(credentials) {
    return request({
      path: '/v1/firebase-social-signin',
      method: 'POST',
      body: credentials,
      baseUrl: hosts.LEGACY_MONOLITH,
    });
  }

  static signup(credentials) {
    return request({
      path: '/v1/signup',
      method: 'POST',
      body: credentials,
      baseUrl: hosts.LEGACY_MONOLITH,
    });
  }

  static signout(refreshToken) {
    if (!refreshToken) return false;
    return request({
      path: '/v1/log-out',
      method: 'POST',
      body: { refreshToken },
      baseUrl: hosts.LEGACY_MONOLITH,
    });
  }

  static async requireRefreshedToken(refreshToken) {
    return request({
      path: '/v1/refresh-token',
      method: 'POST',
      body: { refreshToken },
      baseUrl: hosts.AUTH_SERVICE,
    });
  }

  static requireVerificationEmail(userId) {
    return request({
      path: `/users/${userId}/verify-email-request`,
      method: 'POST',
      baseUrl: hosts.MONOLITH,
    });
  }

  static requireVerificationSms(userId) {
    return request({
      path: `/users/${userId}/verify-phone-request`,
      method: 'POST',
      baseUrl: hosts.MONOLITH,
    });
  }

  static requirePasswordReset(email) {
    return request({
      path: '/v1/reset-password-request',
      method: 'POST',
      body: { email },
      baseUrl: hosts.AUTH_SERVICE,
    });
  }

  static async verifyCaptcha(code) {
    const response = await fetch(
      `${hosts.CAPTCHA_SERVICE}/verify?platform=web&captchaCode=${code}`
    );
    const { success } = await response.json();
    return success;
  }
}
