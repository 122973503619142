export const EVENTS_IN_A_LIST = [
  {
    id: 'c14c7a54-98aa-4284-a6a7-899facd4007a',
    legacyId: 88958,
    name: 'MANILA COCTEL BAR CON HELEN ME LIA DJ',
    slug: 'manila-coctel-bar-con-helen-me-lia-dj-1',
    startingTime: 1598511600,
    endingTime: 1598569200,
    coverUrl:
      'https://images.xceed.me/events/banners/bar-club-manila-con-helen-me-lia-dj-3-barcelona-xceed-1b18.jpg',
    venue: {
      id: '09e0cfb1-3873-4641-ad09-1f92eee3abe6',
      legacyId: 4331,
      name: 'Manila Cocktail BAR',
      slug: 'manila-cocktail-bar',
      coverUrl:
        'https://images.xceed.me/clubs/covers/manila-cocktail-bar-club-barcelona-xceed-496e.png',
      coordinates: {
        latitude: 41.3883325,
        longitude: 2.1578214,
      },
      city: {
        id: '066b6cbb-1035-11ea-b000-06b880e08c2c',
        legacyId: 44,
        name: 'Barcelona',
        slug: 'barcelona',
        coverUrl: 'https://images.xceed.me/cities/covers/barcelona-events-xceed.jpg',
        timezone: 'Europe/Madrid',
      },
    },
  },
  {
    id: '6d98e201-ed31-4d0f-b0d9-c50093ea34be',
    legacyId: 88922,
    name: 'CAMPUS DIGITAL \u003E BRIDGE_48 \u0026 EUMES - 24 al 29 de Agosto -',
    slug: 'campus-digital-bridge-48-eumes-24-al-29-de-agosto',
    startingTime: 1598256000,
    endingTime: 1598652000,
    coverUrl:
      'https://images.xceed.me/events/banners/campus-digital-bridge-48-eumes-24-al-29-de-agosto-barcelona-xceed-2488.png',
    venue: {
      id: '7cef3ac4-919c-11e9-846c-06b12363b88e',
      legacyId: 3166,
      name: 'BRIDGE_48',
      slug: 'bridge-48',
      coverUrl: 'https://images.xceed.me/clubs/covers/bridge-48-club-barcelona-xceed.png',
      coordinates: {
        latitude: 41.3934018,
        longitude: 2.1920907,
      },
      city: {
        id: '066b6cbb-1035-11ea-b000-06b880e08c2c',
        legacyId: 44,
        name: 'Barcelona',
        slug: 'barcelona',
        coverUrl: 'https://images.xceed.me/cities/covers/barcelona-events-xceed.jpg',
        timezone: 'Europe/Madrid',
      },
    },
  },
  {
    id: 'ec83d416-ad08-498e-907c-b7254470a610',
    legacyId: 89049,
    name: 'Thursday | Restaurant, Terrace \u0026 Chill Out',
    slug: 'thursday-restaurant-terrace-chill-out-6',
    startingTime: 1598540400,
    endingTime: 1598569200,
    coverUrl:
      'https://images.xceed.me/events/banners/thursday-restaurant-terrace-chill-out-6-barcelona-xceed-4692.jpg',
    venue: {
      id: '7ce71ef8-919c-11e9-846c-06b12363b88e',
      legacyId: 322,
      name: 'Opium Barcelona',
      slug: 'opium-barcelona',
      coverUrl: 'https://images.xceed.me/clubs/covers/opium-barcelona-club-barcelona-xceed.png',
      coordinates: {
        latitude: 41.383439,
        longitude: 2.195306,
      },
      city: {
        id: '066b6cbb-1035-11ea-b000-06b880e08c2c',
        legacyId: 44,
        name: 'Barcelona',
        slug: 'barcelona',
        coverUrl: 'https://images.xceed.me/cities/covers/barcelona-events-xceed.jpg',
        timezone: 'Europe/Madrid',
      },
    },
  },
  {
    id: 'e4a278a3-f1b5-490c-ae46-89327501cd92',
    legacyId: 89050,
    name: 'Friday | Restaurant, Terrace \u0026 Chill Out',
    slug: 'friday-restaurant-terrace-chill-out-7',
    startingTime: 1598626800,
    endingTime: 1598655600,
    coverUrl:
      'https://images.xceed.me/events/banners/friday-restaurant-terrace-chill-out-7-barcelona-xceed-19ab.jpg',
    venue: {
      id: '7ce71ef8-919c-11e9-846c-06b12363b88e',
      legacyId: 322,
      name: 'Opium Barcelona',
      slug: 'opium-barcelona',
      coverUrl: 'https://images.xceed.me/clubs/covers/opium-barcelona-club-barcelona-xceed.png',
      coordinates: {
        latitude: 41.383439,
        longitude: 2.195306,
      },
      city: {
        id: '066b6cbb-1035-11ea-b000-06b880e08c2c',
        legacyId: 44,
        name: 'Barcelona',
        slug: 'barcelona',
        coverUrl: 'https://images.xceed.me/cities/covers/barcelona-events-xceed.jpg',
        timezone: 'Europe/Madrid',
      },
    },
  },
  {
    id: 'cf186153-4ff4-46e4-bd5d-ddb97a65b83a',
    legacyId: 88956,
    name: 'Jackies pres: Hop On The Top - Hip Hop, Funk \u0026 Soul',
    slug: 'jackies-pres-hop-on-the-top-hip-hop-funk-soul',
    startingTime: 1598713200,
    endingTime: 1598907600,
    coverUrl:
      'https://images.xceed.me/events/banners/jackies-pres-hop-on-the-top-hip-hop-funk-soul-barcelona-xceed-2069.jpg',
    venue: {
      id: '73710832-f6d3-451c-beb6-d55372eda435',
      legacyId: 4335,
      name: 'Touch\u00e9 Beach Garden',
      slug: 'touche-beach-garden',
      coverUrl:
        'https://images.xceed.me/clubs/covers/touche-beach-garden-club-barcelona-xceed-cdad.png',
      coordinates: {
        latitude: 41.3862507,
        longitude: 2.1981613,
      },
      city: {
        id: '066b6cbb-1035-11ea-b000-06b880e08c2c',
        legacyId: 44,
        name: 'Barcelona',
        slug: 'barcelona',
        coverUrl: 'https://images.xceed.me/cities/covers/barcelona-events-xceed.jpg',
        timezone: 'Europe/Madrid',
      },
    },
  },
  {
    id: '3b41e7d6-40c7-426d-855d-cac6dec948c2',
    legacyId: 89051,
    name: 'Saturday | Restaurant, Terrace \u0026 Chill Out',
    slug: 'saturday-restaurant-terrace-chill-out-8',
    startingTime: 1598713200,
    endingTime: 1598742000,
    coverUrl:
      'https://images.xceed.me/events/banners/saturday-restaurant-terrace-chill-out-8-barcelona-xceed-6021.jpg',
    venue: {
      id: '7ce71ef8-919c-11e9-846c-06b12363b88e',
      legacyId: 322,
      name: 'Opium Barcelona',
      slug: 'opium-barcelona',
      coverUrl: 'https://images.xceed.me/clubs/covers/opium-barcelona-club-barcelona-xceed.png',
      coordinates: {
        latitude: 41.383439,
        longitude: 2.195306,
      },
      city: {
        id: '066b6cbb-1035-11ea-b000-06b880e08c2c',
        legacyId: 44,
        name: 'Barcelona',
        slug: 'barcelona',
        coverUrl: 'https://images.xceed.me/cities/covers/barcelona-events-xceed.jpg',
        timezone: 'Europe/Madrid',
      },
    },
  },
  {
    id: 'cabcf83b-6e87-414f-b973-c1c2b6855499',
    legacyId: 89096,
    name: 'Saturday | Restaurant, Terrace \u0026 Chill Out',
    slug: 'saturday-restaurant-terrace-chill-out-9',
    startingTime: 1598716800,
    endingTime: 1598742000,
    coverUrl:
      'https://images.xceed.me/events/banners/saturday-restaurant-terrace-chill-out-9-barcelona-xceed-706b.jpg',
    venue: {
      id: '7ce71f7f-919c-11e9-846c-06b12363b88e',
      legacyId: 323,
      name: 'Pacha Barcelona',
      slug: 'pacha-barcelona',
      coverUrl: 'https://images.xceed.me/clubs/covers/pacha-barcelona-club-barcelona-xceed.png',
      coordinates: {
        latitude: 41.385724,
        longitude: 2.197055,
      },
      city: {
        id: '066b6cbb-1035-11ea-b000-06b880e08c2c',
        legacyId: 44,
        name: 'Barcelona',
        slug: 'barcelona',
        coverUrl: 'https://images.xceed.me/cities/covers/barcelona-events-xceed.jpg',
        timezone: 'Europe/Madrid',
      },
    },
  },
  {
    id: 'b09888b2-c6f7-41f8-89c0-0fe9e935f81a',
    legacyId: 89052,
    name: 'Sunday | Restaurant, Terrace \u0026 Chill Out',
    slug: 'sunday-restaurant-terrace-chill-out-5',
    startingTime: 1598799600,
    endingTime: 1598828400,
    coverUrl:
      'https://images.xceed.me/events/banners/sunday-restaurant-terrace-chill-out-5-barcelona-xceed-1ff0.jpg',
    venue: {
      id: '7ce71ef8-919c-11e9-846c-06b12363b88e',
      legacyId: 322,
      name: 'Opium Barcelona',
      slug: 'opium-barcelona',
      coverUrl: 'https://images.xceed.me/clubs/covers/opium-barcelona-club-barcelona-xceed.png',
      coordinates: {
        latitude: 41.383439,
        longitude: 2.195306,
      },
      city: {
        id: '066b6cbb-1035-11ea-b000-06b880e08c2c',
        legacyId: 44,
        name: 'Barcelona',
        slug: 'barcelona',
        coverUrl: 'https://images.xceed.me/cities/covers/barcelona-events-xceed.jpg',
        timezone: 'Europe/Madrid',
      },
    },
  },
  {
    id: '4f6012b2-c9bc-44b2-9dc4-7f86302b91fe',
    legacyId: 89053,
    name: 'Monday | Restaurant, Terrace \u0026 Chill Out',
    slug: 'monday-restaurant-terrace-chill-out-4',
    startingTime: 1598886000,
    endingTime: 1598914800,
    coverUrl:
      'https://images.xceed.me/events/banners/monday-restaurant-terrace-chill-out-4-barcelona-xceed-50d4.jpg',
    venue: {
      id: '7ce71ef8-919c-11e9-846c-06b12363b88e',
      legacyId: 322,
      name: 'Opium Barcelona',
      slug: 'opium-barcelona',
      coverUrl: 'https://images.xceed.me/clubs/covers/opium-barcelona-club-barcelona-xceed.png',
      coordinates: {
        latitude: 41.383439,
        longitude: 2.195306,
      },
      city: {
        id: '066b6cbb-1035-11ea-b000-06b880e08c2c',
        legacyId: 44,
        name: 'Barcelona',
        slug: 'barcelona',
        coverUrl: 'https://images.xceed.me/cities/covers/barcelona-events-xceed.jpg',
        timezone: 'Europe/Madrid',
      },
    },
  },
  {
    id: 'd2108868-a0d6-4bab-9aeb-1496bc54f856',
    legacyId: 89054,
    name: 'Tuesday | Restaurant, Terrace \u0026 Chill Out',
    slug: 'tuesday-restaurant-terrace-chill-out-4',
    startingTime: 1598972400,
    endingTime: 1599001200,
    coverUrl:
      'https://images.xceed.me/events/banners/tuesday-restaurant-terrace-chill-out-4-barcelona-xceed-3194.jpg',
    venue: {
      id: '7ce71ef8-919c-11e9-846c-06b12363b88e',
      legacyId: 322,
      name: 'Opium Barcelona',
      slug: 'opium-barcelona',
      coverUrl: 'https://images.xceed.me/clubs/covers/opium-barcelona-club-barcelona-xceed.png',
      coordinates: {
        latitude: 41.383439,
        longitude: 2.195306,
      },
      city: {
        id: '066b6cbb-1035-11ea-b000-06b880e08c2c',
        legacyId: 44,
        name: 'Barcelona',
        slug: 'barcelona',
        coverUrl: 'https://images.xceed.me/cities/covers/barcelona-events-xceed.jpg',
        timezone: 'Europe/Madrid',
      },
    },
  },
  {
    id: '6231b2a1-3687-4858-aed3-776cd9ae0301',
    legacyId: 88959,
    name: 'MANILA COCTEL BAR CON HELEN ME LIA DJ',
    slug: 'manila-coctel-bar-con-helen-me-lia-dj-2',
    startingTime: 1599166800,
    endingTime: 1599177600,
    coverUrl:
      'https://images.xceed.me/events/banners/bar-club-manila-con-helen-me-lia-dj-4-barcelona-xceed-8759.jpg',
    venue: {
      id: '09e0cfb1-3873-4641-ad09-1f92eee3abe6',
      legacyId: 4331,
      name: 'Manila Cocktail BAR',
      slug: 'manila-cocktail-bar',
      coverUrl:
        'https://images.xceed.me/clubs/covers/manila-cocktail-bar-club-barcelona-xceed-496e.png',
      coordinates: {
        latitude: 41.3883325,
        longitude: 2.1578214,
      },
      city: {
        id: '066b6cbb-1035-11ea-b000-06b880e08c2c',
        legacyId: 44,
        name: 'Barcelona',
        slug: 'barcelona',
        coverUrl: 'https://images.xceed.me/cities/covers/barcelona-events-xceed.jpg',
        timezone: 'Europe/Madrid',
      },
    },
  },
];

export const EVENT_SIMPLE = EVENTS_IN_A_LIST[0];

// Manipulate the starting and ending time of the event to always be around tomorrow
const ONE_DAY = 86400000;
const THREE_HOURS = 10800000;
const NOW = Date.now();
const selectedDate = NOW + ONE_DAY;
const startingTime = (selectedDate - THREE_HOURS) / 1000;
const endingTime = (selectedDate + ONE_DAY) / 1000;

export const EVENT_SIMPLE_DATES = {
  ...EVENT_SIMPLE,
  startingTime,
  endingTime,
};
