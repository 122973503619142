import { PageNotFoundError } from 'errors';
import Url from 'url-parse';

const HAS_FIRST_PARAM = {
  '/_app': false,
  '/_error': false,
  '/[lang]': false,
  '/[lang]/[geo]': false,
  '/[lang]/[geo]/[...params]': false,
  '/[lang]/[geo]/artists': false,
  '/[lang]/[geo]/artists/[...params]': false,
  '/[lang]/[geo]/artists/all/[...params]': true,
  '/[lang]/[geo]/bar/[...params]': true,
  '/[lang]/[geo]/bars': false,
  '/[lang]/[geo]/bars/[...params]': false,
  '/[lang]/[geo]/bars/all/[...params]': true,
  '/[lang]/[geo]/club/[...params]': true,
  '/[lang]/[geo]/clubs': false,
  '/[lang]/[geo]/clubs/[...params]': false,
  '/[lang]/[geo]/clubs/all/[...params]': true,
  '/[lang]/[geo]/event/[...params]': true,
  '/[lang]/[geo]/events': false,
  '/[lang]/[geo]/events/[...params]': false,
  '/[lang]/[geo]/events/all/[...params]': true,
  '/[lang]/[geo]/restaurant/[...params]': true,
  '/[lang]/[geo]/restaurants': false,
  '/[lang]/[geo]/restaurants/[...params]': false,
  '/[lang]/[geo]/restaurants/all/[...params]': true,
  '/[lang]/artist/[...params]': true,
  '/[lang]/authenticate': false,
  '/[lang]/business': false,
  '/[lang]/cookies-policy': false,
  '/[lang]/privacy-policy': false,
  '/[lang]/reset': false,
  '/[lang]/the-future/covid19': false,
  '/[lang]/tos': false,
};

function _requiredParam(param) {
  throw new TypeError(`The required "${param}" parameter is missing.`);
}

class UrlParser {
  constructor(url = _requiredParam('url'), pathname) {
    const parsedUrl = decodeURI(url).replace(/—|–/g, '--');
    this.url = parsedUrl;
    this.pathname = pathname;
  }

  get url() {
    return this._url;
  }

  set url(url) {
    this._url = Url(url, true);
    return this;
  }

  get filterParam() {
    if (!this.hasFilterParam()) return null;
    const [, filterStr] = this.url.pathname.split('/').find(this.hasFilterParam).split('--');
    return filterStr;
  }

  get channel() {
    if (!this.hasChannel()) return undefined;
    const [, channel] = this.url.pathname.split('/').find(this.hasChannel).split('--');
    return channel;
  }

  hasFilters() {
    return this.hasFilterParam() || this.hasFilterQuery();
  }

  hasFilterParam(path = this.url.pathname) {
    return path.includes('filters--');
  }

  hasFilterQuery(query = this.url.query) {
    return Boolean(query.startDate || query.endDate);
  }

  hasChannel(path = this.url.pathname) {
    return path.includes('channel--');
  }

  validateParams(params) {
    const hasFilters = this.hasFilterParam();
    const hasChannel = this.hasChannel();
    const len = HAS_FIRST_PARAM[this.pathname] ? 1 : 0;
    const catchLen = params?.length || 0;
    const oneParamCondition = catchLen === len + 0 && !hasFilters && !hasChannel;
    const twoParamCondition = catchLen === len + 1 && (hasFilters || hasChannel);
    const threeParamCondition = catchLen === len + 2 && hasFilters && hasChannel;
    if (oneParamCondition || twoParamCondition || threeParamCondition) return this;
    throw new PageNotFoundError(
      `Page not found, unknown params in xceed.me${this.url.pathname}`,
      this.url.pathname
    );
  }
}

export default UrlParser;
