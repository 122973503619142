/**
 * Available hosts for the client, extensible if need for new sources.
 */

function _requiredEnv(env) {
  throw new TypeError(`The ${env} environment variable is strictly required.`);
}

export default {
  MONOLITH: process.env.NEXT_PUBLIC_MONOLITH_HOST || _requiredEnv('MONOLITH_HOST'),
  LEGACY_MONOLITH:
    process.env.NEXT_PUBLIC_LEGACY_MONOLITH_HOST || _requiredEnv('LEGACY_MONOLITH_HOST'),
  LEGACY_API: process.env.NEXT_PUBLIC_LEGACY_API_HOST || _requiredEnv('LEGACY_API_HOST'),
  TABLE_MANAGEMENT:
    process.env.NEXT_PUBLIC_TABLE_MANAGEMENT_HOST || _requiredEnv('TABLE_MANAGEMENT_HOST'),
  EVENTS_SERVICE:
    process.env.NEXT_PUBLIC_EVENTS_SERVICE_HOST || _requiredEnv('EVENTS_SERVICE_HOST'),
  AUTH_SERVICE: process.env.NEXT_PUBLIC_AUTH_SERVICE_HOST || _requiredEnv('AUTH_SERVICE_HOST'),
  CAPTCHA_SERVICE:
    process.env.NEXT_PUBLIC_CAPTCHA_SERVICE_HOST || _requiredEnv('CAPTCHA_SERVICE_HOST'),
  NEWS_SERVICE: process.env.NEXT_PUBLIC_NEWS_SERVICE_HOST || _requiredEnv('NEWS_SERVICE_HOST'),
  USERS_SERVICE: process.env.NEXT_PUBLIC_USERS_SERVICE_HOST || _requiredEnv('USERS_SERVICE_HOST'),
  PAYMENTS_SERVICE:
    process.env.NEXT_PUBLIC_PAYMENTS_SERVICE_HOST || _requiredEnv('PAYMENTS_SERVICE_HOST'),
  OFFER_SERVICE: process.env.NEXT_PUBLIC_OFFER_SERVICE_HOST || _requiredEnv('OFFER_SERVICE_HOST'),
};
