import React, { useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

const FacebookPixelContext = React.createContext();
FacebookPixelContext.displayName = 'FacebookPixelContext';

export const FacebookPixelProvider = ({ pixelId, ...props }) => {
  const [facebookPixelId, setFacebookPixelId] = useState(pixelId);

  const context = useMemo(() => ({ facebookPixelId, setFacebookPixelId }), [facebookPixelId]);
  return <FacebookPixelContext.Provider value={context} {...props} />;
};

export function useFacebookPixel() {
  const context = useContext(FacebookPixelContext);
  if (!context) throw new Error('useFacebookPixel must be used within FacebookPixelProvider!');
  return context;
}

FacebookPixelProvider.propTypes = {
  pixelId: PropTypes.string,
};
