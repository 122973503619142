import { add } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

import UrlParser from '../urlParser';

import { defaults, filterSchema } from './config';

function _dashToCamel(str) {
  return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
}

function _camelToDash(str) {
  return str.replace(/([A-Z])/g, g => `-${g[0].toLowerCase()}`);
}

function _extractDateString(date) {
  if (typeof date === 'string') date = new Date(date);
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${date.getFullYear()}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day}`;
}

function _alphabeticCompare(curr, next) {
  if (curr < next) return -1;
  if (curr > next) return 1;
  return 0;
}

function _queryToString(query) {
  const res = {};
  if (query.startDate) res.startDate = _extractDateString(query.startDate);
  if (query.endDate) res.endDate = _extractDateString(query.endDate);
  return res;
}

class Filter extends UrlParser {
  constructor(url) {
    super(url);
    this._filters = this.composeFilterObj();
  }

  get filters() {
    return this._filters;
  }

  getDateFilter(query = this.url.query) {
    const { startDate, endDate } = query;
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const startDateTZ = startDate ? utcToZonedTime(startDate, tz) : null;
    const endDateTZ = endDate ? utcToZonedTime(endDate, tz) : null;

    return {
      startDate: startDateTZ,
      endDate: endDateTZ,
    };
  }

  setDateFilter(dates) {
    const query = _queryToString(dates);
    this.url.set('query', query);
    return this;
  }

  static parseFilters(selection) {
    const filtersList = selection.split('&');
    return filtersList.reduce((res, filter) => {
      const [name, valueStr] = filter.split(/_(.+)/);
      const filterName = _dashToCamel(name);
      return { ...res, [filterName]: valueStr.split(',').sort((a, b) => (a > b ? 1 : -1)) };
    }, {});
  }

  static filterBySelection(filters, schema) {
    return schema.reduce((res, filter) => {
      res[filter] = filters[filter] || defaults[filter];
      return res;
    }, {});
  }

  composeFilterObj() {
    const hasFilters = this.hasFilters();
    if (!hasFilters) return defaults;
    let filters = {};
    if (this.hasFilterParam()) filters = Filter.parseFilters(this.filterParam);
    if (this.hasFilterQuery()) filters.dates = this.getDateFilter();
    return filters;
  }

  composeFilterParam(filters = this.filters) {
    return (
      Object.entries(filters)
        // Filter out what's not an array and empty arrays
        .filter(([, value]) => value.length)
        // Sort entries by key name
        .sort(([key1], [key2]) => _alphabeticCompare(key1, key2))
        // Compose the key_value1,value2,etc string sorted by values
        .map(([key, value]) => `${_camelToDash(key)}_${value.sort(_alphabeticCompare).join(',')}`)
        .join('&')
    );
  }

  composeFilterQuery() {
    return (
      Object.entries(_queryToString(this.getDateFilter()))
        // Compose the key1=value1,key2=value2,etc string
        .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
        .join('&')
    );
  }

  composeFilterStr() {
    const param = this.composeFilterParam();
    const query = this.composeFilterQuery();
    return [param && `filters--${param}`, query && `?${query}`].filter(Boolean).join('');
  }

  getFiltersByPage(page) {
    if (!page) return this.filters;
    return Filter.filterBySelection(this.filters, filterSchema[page]);
  }

  setFilter(filter) {
    if (filter.dates) this.setDateFilter(filter.dates);
    this._filters = {
      ...defaults,
      ...this.filters,
      ...filter,
    };
    return this;
  }

  toPath() {
    const composedPath = this.composeFilterParam();
    const hasPreviousFilters = this.hasFilterParam(this.url.pathname);
    const filters = composedPath && `filters--${composedPath}`;
    let newPath;
    if (filters && hasPreviousFilters) {
      newPath = this.url.pathname
        .split('/')
        .map(param => (this.hasFilterParam(param) ? filters : param))
        .join('/');
    } else if (filters && !hasPreviousFilters) {
      newPath = `${this.url.pathname}/${filters}`;
    } else {
      newPath = this.url.pathname
        .split('/')
        .filter(param => !this.hasFilterParam(param))
        .join('/');
    }

    this.url.set('pathname', newPath);
    return this.url.href.split(this.url.origin).pop();
  }

  formatToQueryStrings() {
    const startDate =
      this.filters.dates?.startDate && add(this.filters.dates.startDate, { hours: 7 });
    const endDate =
      this.filters.dates?.endDate &&
      add(this.filters.dates.endDate, { minutes: 59, hours: 6, days: 1 });

    const formatedFilters = {
      vibes: this.filters.vibes?.join(','),
      musicGenres: this.filters.musicGenres?.join(','),
      venueCharacteristics: this.filters.venues?.join(','),
      foodTypes: this.filters.foodTypes?.join(','),
      priceLevel: this.filters.priceLevel?.join(','),
      facilities: this.filters.facilities?.join(','),
      openNow: this.filters.openNow?.join(','),
      ...(endDate && { endTime: Date.parse(endDate) / 1000 }),
      ...(startDate && { startTime: Date.parse(startDate) / 1000 }),
    };
    return formatedFilters;
  }

  reset() {
    this.setFilter(defaults);
    return this;
  }
}

export default Filter;
