import { Followed } from 'models/Followed';
import { normalizeByKey } from 'utils/normalizeByKey';

const defaultUser = {
  id: null,
  legacyId: null,
  firstName: null,
  lastName: null,
  profileImageUrl: null,
  email: null,
  isEmailVerified: false,
  isPhoneVerified: false,
  isVerified: false,
  lang: 'en',
  birthDate: null,
  birthPlace: null,
  gender: null,
  phone: null,
  isNightgraphUser: false,
  musicGenres: [],
  paymentMethods: [],
  paymentAccounts: [],
  saved: new Followed(),
  reviews: null,
};

export class User {
  constructor(user = defaultUser) {
    Object.assign(this, User.parse({ ...defaultUser, ...user }));
  }

  static parse(user) {
    let { paymentAccounts, reviews } = user;
    if (Array.isArray(paymentAccounts)) {
      paymentAccounts = normalizeByKey('paymentPlatform', paymentAccounts);
    }
    if (Array.isArray(reviews)) {
      reviews = normalizeByKey('venue.id', reviews);
    }
    return { ...user, paymentAccounts, reviews };
  }
}
