import React, { useMemo } from 'react';
import { QueryCache, QueryClient, QueryClientProvider } from 'react-query';
import PropTypes from 'prop-types';

import { useTranslation } from 'i18n/';
import { useNotify } from 'services/notifications';

export function ReactQueryProvider({ children }) {
  const notify = useNotify();
  const { lang, t } = useTranslation();

  const queryCache = useMemo(
    () =>
      new QueryCache({
        defaultConfig: {
          queries: {
            refetchOnWindowFocus: false,
          },
        },
      }),
    []
  );

  const config = useMemo(
    () => ({
      queries: {
        onError: error => notify.error(t('common:fetchError', { msg: error.message })),
      },
    }),
    [lang]
  );

  const client = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: Infinity,
        ...config.queries,
      },
    },
    queryCache,
  });

  return <QueryClientProvider client={client}>{children}</QueryClientProvider>;
}

ReactQueryProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
