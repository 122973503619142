import { arrayOf, bool, number, oneOf, shape, string } from 'prop-types';

import { filterSchema } from '../services/filterClient/config';

/**
 * SUB-ENTITIES
 */
const MusicGenre = shape({
  id: string,
  name: string.isRequired,
  slug: string,
});

const Track = shape({
  url: string,
  name: string,
});

const CitySimple = shape({
  id: string,
  coverUrl: string,
  legacyId: number,
  slug: string.isRequired,
  name: string.isRequired,
  timezone: string.isRequired,
});

const Coordinates = shape({
  latitude: number.isRequired,
  longitude: number.isRequired,
});

const Review = shape({
  id: string.isRequired,
  rating: number.isRequired,
  description: string.isRequired,
  createdAt: number.isRequired,
  author: shape({
    id: string,
    legacyId: number,
    firstName: string,
    lastName: string,
    profileImageUrl: string,
  }).isRequired,
  venue: shape({
    id: string,
  }).isRequired,
});

/**
 * ENTITIES
 */
const Artist = shape({
  id: string.isRequired,
  legacyId: number.isRequired,
  slug: string.isRequired,
  coverUrl: string.isRequired,
  name: string.isRequired,
  isGeneric: bool,
  description: string,
  tracks: arrayOf(Track).isRequired,
  musicGenres: arrayOf(MusicGenre).isRequired,
});

const Event = shape({
  id: string.isRequired,
  legacyId: number.isRequired,
  slug: string.isRequired,
  coverUrl: string.isRequired,
  name: string.isRequired,
  startingTime: number.isRequired,
  endingTime: number.isRequired,
  venue: shape({
    name: string,
  }).isRequired,
});

const Club = shape({
  id: string.isRequired,
  legacyId: number.isRequired,
  slug: string.isRequired,
  coverUrl: string.isRequired,
  name: string.isRequired,
  city: CitySimple.isRequired,
  coordinates: Coordinates,
});

const Bar = shape({
  id: string.isRequired,
  name: string.isRequired,
  slug: string.isRequired,
  coverUrl: string.isRequired,
  city: CitySimple.isRequired,
  coordinates: Coordinates,
  priceLevel: number,
});

const Restaurant = Bar;

/**
 * CONFIG
 */
const FilterPage = oneOf(Object.keys(filterSchema));

export {
  Artist,
  Event,
  Club,
  CitySimple,
  Coordinates,
  FilterPage,
  MusicGenre,
  Track,
  Bar,
  Restaurant,
  Review,
};
