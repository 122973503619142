import React, { useContext } from 'react';

const StaticDataContext = React.createContext(
  new Error('useStaticData must be used within StaticDataContext!')
);

export const StaticDataProvider = StaticDataContext.Provider;
StaticDataProvider.defaultProps = { value: { isFromBot: false, isDefaultSite: false } };
StaticDataContext.displayName = 'StaticDataContext';

export function useStaticData() {
  const context = useContext(StaticDataContext);
  if (context instanceof Error) throw context;
  return context;
}
