import React, { useContext, useMemo } from 'react';
import { useQuery } from 'react-query';
import { arrayOf, exact } from 'prop-types';
import * as Types from 'types';

import { useTranslation } from 'i18n/';
import { CityController } from 'services/apiClient/interfaces/CityController';

const CitiesContext = React.createContext(
  new Error('useCities must be used within CitiesContext!')
);
CitiesContext.displayName = 'CitiesContext';

export function CitiesProvider({ initialValue, ...props }) {
  const { lang } = useTranslation();
  const { cities: initialCities, popularCities: initialPopular } = initialValue;

  const { data: cities = [] } = useQuery(
    ['all-cities', { lang }],
    () => CityController.getAll({ lang }),
    {
      initialData: initialCities,
      staleTime: Infinity,
    }
  );

  const { data: popularCities = [] } = useQuery(
    ['popular-cities', { lang }],
    () => CityController.getPopular({ lang }),
    { initialData: initialPopular, staleTime: Infinity }
  );

  const context = useMemo(
    () => [
      { cities, popularCities },
      function getCityBySlug(slug) {
        return cities.find(city => city.slug === slug);
      },
    ],
    [cities, popularCities]
  );
  return <CitiesContext.Provider value={context} {...props} />;
}

export function useCities() {
  const context = useContext(CitiesContext);
  if (context instanceof Error) throw context;
  return context;
}

CitiesProvider.propTypes = {
  initialValue: exact({
    cities: arrayOf(Types.CitySimple),
    popularCities: arrayOf(Types.CitySimple),
  }),
};
CitiesProvider.defaultProps = { initialValue: { cities: undefined, popularCities: undefined } };
