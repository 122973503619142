export function normalizeByKey(key, list = []) {
  const paths = key.split('.');

  return list.reduce((acc, item) => {
    const itemKey = paths.reduce((object, path) => {
      return object[path];
    }, item);
    return { ...acc, [itemKey]: item };
  }, {});
}
