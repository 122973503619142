import Cookies from 'js-cookie';

import {
  ACCESS_TOKEN_KEY,
  IMPERSONATE_ACCESS_TOKEN_KEY,
  IMPERSONATE_REFRESH_TOKEN_KEY,
  REFRESH_TOKEN_EXPIRATION_TIME,
  REFRESH_TOKEN_KEY,
  SAME_SITE_STRICT,
  STORAGE_ACCESS_TOKEN_KEY,
  STORAGE_IMPERSONATE_ACCESS_TOKEN_KEY,
  STORAGE_IMPERSONATE_REFRESH_TOKEN_KEY,
  STORAGE_REFRESH_TOKEN_KEY,
} from '../constants/cookies';
import { IS_DEV_NODE_ENV } from '../constants/nodeEnv';

const cookieDomain = IS_DEV_NODE_ENV ? 'localhost' : '.xceed.me';

const maxDaysRefreshToken = 90;

export const getAccessToken = () => Cookies.get(ACCESS_TOKEN_KEY);

export const removeAccessToken = () => {
  Cookies.remove(ACCESS_TOKEN_KEY, {
    domain: cookieDomain,
    path: '/',
    secure: true,
    sameSite: SAME_SITE_STRICT,
  });
  Cookies.remove(IMPERSONATE_ACCESS_TOKEN_KEY, {
    domain: cookieDomain,
    path: '/',
    secure: true,
    sameSite: SAME_SITE_STRICT,
  });
  if (typeof window !== 'undefined') {
    sessionStorage.removeItem(STORAGE_ACCESS_TOKEN_KEY);
    sessionStorage.removeItem(STORAGE_IMPERSONATE_ACCESS_TOKEN_KEY);
  }
};

export const setAccessToken = token => {
  const maxMinutesAccessToken = new Date(
    new Date().getTime() + Number(REFRESH_TOKEN_EXPIRATION_TIME)
  );
  Cookies.set(ACCESS_TOKEN_KEY, token, {
    domain: cookieDomain,
    path: '/',
    secure: true,
    sameSite: SAME_SITE_STRICT,
    expires: maxMinutesAccessToken,
  });
  if (typeof window !== 'undefined' && STORAGE_ACCESS_TOKEN_KEY) {
    sessionStorage.setItem(STORAGE_ACCESS_TOKEN_KEY, token);
  }
};

export const getRefreshToken = () => Cookies.get(REFRESH_TOKEN_KEY);

export const removeRefreshToken = () => {
  Cookies.remove(REFRESH_TOKEN_KEY, {
    domain: cookieDomain,
    path: '/',
    secure: true,
    sameSite: SAME_SITE_STRICT,
    expires: maxDaysRefreshToken,
  });
  Cookies.remove(IMPERSONATE_REFRESH_TOKEN_KEY, {
    domain: cookieDomain,
    path: '/',
    secure: true,
    sameSite: SAME_SITE_STRICT,
    expires: maxDaysRefreshToken,
  });
  if (typeof window !== 'undefined') {
    localStorage.removeItem(STORAGE_REFRESH_TOKEN_KEY);
    localStorage.removeItem(STORAGE_IMPERSONATE_REFRESH_TOKEN_KEY);
  }
};

export const setRefreshToken = token => {
  Cookies.set(REFRESH_TOKEN_KEY, token, {
    domain: cookieDomain,
    path: '/',
    secure: true,
    sameSite: SAME_SITE_STRICT,
    expires: maxDaysRefreshToken,
  });
  if (typeof window !== 'undefined' && STORAGE_REFRESH_TOKEN_KEY) {
    localStorage.setItem(STORAGE_REFRESH_TOKEN_KEY, token);
  }
};
