/* eslint-disable no-console */
import 'intersection-observer';

import React from 'react';
import { ReactQueryDevtools } from 'react-query/devtools';
import * as Sentry from '@sentry/nextjs';
import App from 'next/app';
import PropTypes from 'prop-types';
import ROPolyfill from 'resize-observer-polyfill';

import { JukeboxProvider } from '@xceedsrl/jukebox';

import { sendLogsToLogstash } from 'constants/logstash';
import { IS_DEV_NODE_ENV } from 'constants/nodeEnv';
import { CityController } from 'services/apiClient/interfaces/CityController';
import { PromoterController } from 'services/apiClient/interfaces/PromoterController';
import { AuthProvider } from 'services/authClient';
import { CheckoutProvider } from 'services/checkoutClient';
import GoogleTagManager from 'services/GoogleTagManager';
import { loggerToFile, loggerToLogstash } from 'services/Logger';
import NotificationProvider from 'services/notifications';
import installScripts from 'services/scriptCache';
import UrlParser from 'services/urlParser';
import { AudioProvider } from 'store/audio';
import { ChannelProvider } from 'store/channel';
import { CitiesProvider } from 'store/cities';
import { FacebookPixelProvider } from 'store/facebookPixel';
import { FiltersProvider } from 'store/filters';
import { ReactQueryProvider } from 'store/reactQuery';
import { SharedDataProvider } from 'store/sharedData';
import { StaticDataProvider } from 'store/staticData';
import { getRefreshToken } from 'utils/authTokens';
import { isBot } from 'utils/isBot';
import { hasLangCode } from 'utils/regExp';

import { Compose } from 'components/Compose';
import Layout from 'components/Layout';
import NProgress from 'components/NProgress';

// eslint-disable-next-line import/named
import { appWithI18n, i18nConfig } from '../i18n';

// eslint-disable-next-line import/no-extraneous-dependencies
import 'mapbox-gl/dist/mapbox-gl.css';
import 'react-credit-cards/es/styles-compiled.css';

if (
  process.env.NEXT_PUBLIC_API_MOCKING === 'enabled' ||
  process.env.NEXT_PUBLIC_PROJECT_ENV === 'testing'
) {
  // eslint-disable-next-line global-require
  require('../../mocks');
}

export function reportWebVitals(metric) {
  if (metric.label === 'web-vital') {
    GoogleTagManager.sendWebVitalsMetrics(metric);
  }
}

const renderComponent = (Component, pageProps, router) => {
  if (typeof window === 'undefined') {
    return Component.AuthenticatedRoute ? null : <Component {...pageProps} />;
  }

  const refreshToken = getRefreshToken();

  if (Component.AuthenticatedRoute && !refreshToken) {
    router.replace('/authenticate');
    return null;
  }

  return <Component {...pageProps} />;
};

class MyApp extends App {
  state = {
    pixelId: '',
  };

  async componentDidMount() {
    installScripts();
    if (typeof window !== 'undefined') {
      if (!window.ResizeObserver) window.ResizeObserver = ROPolyfill;
    }
  }

  render() {
    const { Component, pageProps, initialCities, channel, staticData, router, facebookPixel } =
      this.props;
    const componentTheme = Component.Theme;
    const FB_PIXEL_ID = facebookPixel || process.env.NEXT_PUBLIC_FB_PIXEL_ID;

    return (
      <Compose
        components={[
          JukeboxProvider,
          NotificationProvider,
          ReactQueryProvider,
          SharedDataProvider,
          [ChannelProvider, { value: channel }],
          [StaticDataProvider, { value: staticData }],
          [CitiesProvider, { initialValue: initialCities }],
          AuthProvider,
          CheckoutProvider,
          AudioProvider,
          FiltersProvider,
          [FacebookPixelProvider, { pixelId: FB_PIXEL_ID }],
        ]}
      >
        <script
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: `!function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '${FB_PIXEL_ID}');
      fbq('track', 'PageView');`,
          }}
        />

        <noscript
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: `
          <img height="1" width="1" style="display:none" 
              src={https://www.facebook.com/tr?id={${FB_PIXEL_ID}}&ev=PageView&noscript=1}/>`,
          }}
        />
        <Layout componentTheme={componentTheme}>
          {renderComponent(Component, pageProps, router)}
        </Layout>
        <NProgress componentTheme={componentTheme} />
        <ReactQueryDevtools initialIsOpen={false} />
      </Compose>
    );
  }
}

MyApp.getInitialProps = async ({ Component, ctx, lang }) => {
  try {
    const { req, asPath, pathname, query } = ctx;

    // Parse the url to extract info about such as channel and filters
    const url = new UrlParser(asPath, pathname);

    const isServer = typeof window === 'undefined';
    const isDev = process.env.NEXT_PUBLIC_PROJECT_ENV === 'development';
    const ua = req?.headers['user-agent'] || '';
    const isBotRequest = isServer && isBot(ua);
    const { channel } = url;
    const hasFilters = url.hasFilters();

    if (isDev) {
      console.log('=============================================');
      console.log('REQUEST DETAILS');
      console.table({
        isBotRequest,
        isServer,
        asPath,
        hasFilters,
        Environment: process.env.NEXT_PUBLIC_PROJECT_ENV,
        'User Agent': ua.slice(0, 45),
        Channel: channel,
        Page: pathname,
        ...query,
      });
      console.log('=============================================');
    }

    let pageProps = {};
    let initialCities;
    let staticData = {
      isFromBot: isBotRequest,
      isDefaultSite: false,
    };

    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps({
        ...ctx,
        url,
        isServer,
        isBotRequest,
        channel,
        hasFilters,
      });
    }

    if (isBotRequest) {
      const [cities, popularCities] = await Promise.all([
        CityController.getAll({ lang }),
        CityController.getPopular({ lang }),
      ]);

      initialCities = {
        cities,
        popularCities,
      };

      staticData = {
        isDefaultSite: !hasLangCode(req.url),
        isFromBot: true,
      };
    }

    let facebookPixel = null;
    if (channel) {
      const promoterInfo = await PromoterController.getInfo(channel);
      console.log('promoterInfo', promoterInfo);
      facebookPixel = promoterInfo?.facebookPixel;
    }

    return { pageProps, initialCities, channel, staticData, facebookPixel };
  } catch (error) {
    const { asPath, pathname } = ctx;
    const url = new UrlParser(asPath, pathname);
    if (!process.browser) {
      if (IS_DEV_NODE_ENV) loggerToFile.error({ error, url: url._url });
      if (sendLogsToLogstash) loggerToLogstash.error({ error, url: url._url });
    }
    Sentry.captureException(error);
    throw error;
  }
};

MyApp.propTypes = {
  Component: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  pageProps: PropTypes.objectOf(PropTypes.any).isRequired,
  initialCities: PropTypes.exact({ cities: PropTypes.array, popularCities: PropTypes.array }),
  channel: PropTypes.string,
  staticData: PropTypes.exact({
    isDefaultSite: PropTypes.bool,
    isFromBot: PropTypes.bool,
  }),
};

export default appWithI18n(MyApp, i18nConfig);
