import { Club } from 'models/Club';

export const defaultEvent = {
  id: null,
  legacyId: null,
  name: null,
  about: null,
  slug: null,
  startingTime: null,
  endingTime: null,
  coverUrl: null,
  settings: {
    isPrivate: false,
    tableManagementEnabled: false,
  },
  venue: new Club(),
  musicGenres: [],
  vibes: [],
  dressCode: null,
  agePolicy: null,
  lineup: [],
};

export class Event {
  constructor(event = defaultEvent) {
    Object.assign(this, Event.parse({ ...defaultEvent, ...event }));
  }

  static parse(event) {
    const { venue } = event;
    return { ...event, venue: new Club(venue) };
  }
}
